import React, { useEffect, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import yaml from "yaml";
import Panel from "./Panel";

const yamlCalc = `
calculator:
    - panel:
        - element: 'h1'
          value: Meter to something
        - element: 'input'
          label: Meters
          type: 'number'
          name: 'meter'
          value: 1
        - element: 'paragraph'
          value: $'meter/100'
        - element: 'paragraph'
          value: $'meter * 100'
    - panel:
        - element: 'h1'
          value: Dollar - Euro
        - element: 'input'
          label: conversion
          name: euroDollarMultiplier
          value: 1.3
          type: 'dropdown'
          items:
            - label: Euro to Dollar
              value: 1.3
            - label: Dollar to euro
              value: 0.8
        - element: 'input'
          label: Dollars
          type: 'number'
          name: 'dollar'
          value: 1
        - element: 'paragraph'
          value: $'dollar * euroDollarMultiplier'
    - panel:
          - element: 'h1'
            value: Eindkapitaal
          - element: 'input'
            label: Initiele inleg
            type: 'text'
            name: 'initial'
            value: 1
          - element: 'input'
            label: Maandelijks
            type: 'text'
            name: 'monthly'
            value: 100
          - element: 'input'
            label: Periode (jaar)
            type: 'text'
            name: 'year'
            value: 20
          - element: 'input'
            label: Interest
            type: 'text'
            name: 'interest'
            value: 7
          - element: 'paragraph'
            value: >
              $'(initial * (1+ (interest/100/12) )^(year*12) ) + (monthly * ( ( (1+(interest/100/12)) ^(year*12) - 1) / (interest/100/12)))'
`;

// 

const parsed = yaml.parse(yamlCalc);

function getInitialValues(dsl: any) {
  return dsl.calculator.reduce((obj: { [key: string]: number }, component: any) => ({ 
    ...obj,
    ...component.panel.filter((panel: any) => panel.name).reduce((acc: { [key: string]: number }, panel: any) => ({...acc,
      [panel.name]: panel.value
    }),{}),
  }),{});
}

const Calculator = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const initialValues = useMemo(() => getInitialValues(parsed), []);
    const onInit = useCallback(values => dispatch({type: 'init', initialState: values }), [dispatch]);

    useEffect(() => {
        onInit(initialValues);
    }, [initialValues, onInit]);

    if(Object.keys(state).length) {
        return  <Form>
        {parsed.calculator.map((component: any, i: number) => (
          <Panel key={i} panel={component.panel} />
        ))}
      </Form>
    }

    return <div>Loading</div>

 
};


export default Calculator;
