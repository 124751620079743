import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Calculator from './Calculator';
import { Container } from 'react-bootstrap';


function App() {
  return (
    <div className="App">
      <Container>
        <Calculator></Calculator>
      </Container>
    </div>
  );
}

export default App;
