const initialState = {

};

const reducer = (state: any = initialState, action: any) => {
    switch(action.type) {
        case 'init':
            return action.initialState;
        case 'change':
            return {...state, [action.field]: action.value};
        default:
            return state;
    }
};

export default reducer;