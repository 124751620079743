import React, { useCallback } from "react";
import { Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Component from "./Component";

const Panels = ({ panel }: any) => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const onChange = useCallback((field: string) => (value: any) => dispatch({ type: 'change', field, value }), [dispatch]);
  return (
    <Card className="mb-2 mt-2">
      <Card.Body>
        <Form.Group>
        {panel.map((component: any, i: number) => (
          <Component key={i} {...component} state={state} value={state[component.name] || component.value} onChange={(ev: any) =>  onChange(component.name)(ev.target.value )} />
        ))}</Form.Group>
      </Card.Body>
    </Card>
  );
};

export default Panels;

