import React from 'react';
import {evaluate} from 'mathjs'
import { Form } from 'react-bootstrap';

const renderValue = (value: string, state: any) => {
    value = value.trim();
    if (value.startsWith('$\'') && value.endsWith('\'')) {
        const formula = value.substr(2, value.length - 3);
        return evaluate(formula, state);
    }

    return value;
}

const inputs: any = {
    number: ({ type, value, onChange, label }: any) => <div><Form.Label>{label}</Form.Label> <Form.Control type={type} value={value} onChange={onChange} /></div>,
    text: ({ type, value, onChange, label }: any) => <div><Form.Label>{label}</Form.Label> <Form.Control type={type} value={value} onChange={onChange} /></div>,
    dropdown: ({ type, value, onChange, label, defaultValue, items }: any) => <div>
        <Form.Label>{label}</Form.Label> <Form.Control type={type} as="select" value={value} onChange={onChange}>{
            items.map(({ label, value }: any) => <option value={value}>{label}</option>)
        }</Form.Control>
    </div>
}


const components: any = {
    paragraph: ({value, state}: any) => <p>{renderValue(value, state)}</p>,
    input: (props: any) => inputs[props.type](props),
    h1: ({value}: any) => <h1>{value}</h1>,
}

export default (props: any) => {
    const Component = components[props.element] as any;
    return <Component {...props} />
}